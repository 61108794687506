<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <!-- tag查询框 -->
    <div class="datePicker">
      访问日期：
      <a-range-picker
        v-model="timeVal"
        @change="onChange_range_picker"
        style="width: 300px"
      />
      <!-- @click="searchBtn" -->
      <a-button type="primary" style="margin-left: 30px">查询</a-button>
      <a-button style="position: absolute; right: 20px" @click="expotExcel()">
        <a-icon type="file-excel" style="color: green" /> 批量导出</a-button
      >
    </div>
    <div style="padding: 20px; background-color: #fff">
      <a-table
        :columns="columns"
        style="min-height: 500px; background-color: #fff"
        :data-source="HeplTable"
        :rowKey="(record) => record.id"
        :pagination="false"
      >
        <span slot="channelType" slot-scope="text, item, index">
          <!-- {{ index + (curPage - 1) * pageSize + 1 }} -->
          <span v-if="text == '' || text == 'undefined'">未知</span>
          <span>{{ text }}</span>
          <!-- <span v-if="text == 'DOCUMENT_PDF'">PDF收藏</span>
              <span v-if="text == 'DOCUMENT_LIST'">文献指南列表页</span> -->
          <!-- <span v-if="text == 'auto'">进入页面已读</span> -->
        </span>
      </a-table>
      <!-- table框的分页 -->
      <div
        style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
          {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          v-model="pagination.current"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
//   import {getSearchRecord,} from "@/service/DrugQueryManagement";
import {
  getHomePageVisitedRecords,
  getExportHomePage,
} from "../../../service/document_api";
export default {
  data() {
    return {
      timeVal: [],
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          // align: "center",
          // width: "8%",
        },
        {
          title: "医院",
          dataIndex: "hospital",
          key: "hospital",
          // align: "center",
          // width: "15%",
        },
        {
          title: "科室",
          dataIndex: "offices",
          key: "offices",
          // align: "center",
          // width: "28%",
        },
        {
          title: "职称",
          dataIndex: "title",
          key: "title",
          // align: "center",
          // scopedSlots: { customRender: "cancelChannel" },
        },
        {
          title: "访问主页",
          // width: "13%",
          dataIndex: "visitedName",
          key: "visitedName",
        },
        {
          title: "位置",
          // width: "13%",
          dataIndex: "position",
          key: "position",
        },
        {
          title: "渠道",
          // width: "13%",
          dataIndex: "channel",
          key: "channel",
        },
        {
          title: "访问时间",
          // width: "13%",
          dataIndex: "createdTime",
          key: "createdTime",
        },
      ],
      HeplTable: [],
      StartTime: "",
      EndTime: "",
    };
  },

  created() {
    this.getTable();
  },
  methods: {
    // 日期
    onChange_range_picker(date, dateString) {
      this.StartTime = dateString[0];
      this.EndTime = dateString[1];
      this.pagination.pageSize = 10;
      this.pagination.pageNum = 1;
      this.pagination.current = 1;
      this.getTable();
    },
    // 分页
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTable();
    },
    // 获取列表
    async getTable() {
      let data = {};
      if (this.StartTime == "") {
        this.StartTime = null;
      }
      if (this.EndTime == "") {
        this.EndTime = null;
      }
      data = {
        begin: this.StartTime,
        end: this.EndTime,
        page: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
      };
      const response = await getHomePageVisitedRecords(data);
      if (response.code === 200) {
        console.log("浏览请求", response.data);
        this.HeplTable = response.data;
        this.pagination.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
    },
    // 导出
    async expotExcel() {
      const data = {
        begin: this.StartTime,
        end: this.EndTime,
        ExelName: "个人主页访问记录",
      };
      const response = await getExportHomePage(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  // margin-bottom: 10px;
  position: relative;
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
</style>
